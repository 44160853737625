<template>
    <v-footer 
        padless
        app
        fixed
        color="grey darken-3"
        v-if="warehouse.shelf_changed"
        >
        <v-col
        class="text-right"
        cols="12"
        >
            <v-btn
                outlined
                color="white"
                class="mr-8"
                :disabled="loading"
                @click="$store.commit('warehouse/DISCARD_CHANGES')"
                >
                Discard changes
            </v-btn>
            <v-btn
                color="primary darken-2"
                :loading="loading"
                @click="save_shelf"
                class=""
                >
                Save
            </v-btn>
        </v-col>
    </v-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      warehouse: state => state.warehouse,
    }),
  },
    components: {
      
    },
  data() {
    return {
      loading: false,
      search: '',
    }
  },
  methods: {
    async save_shelf(){
      this.loading = true
      await this.$store.dispatch('warehouse/save_shelf')
      this.loading = false
    }
  },
  mounted(){
  
  },
}
</script>

<style lang="scss" scoped>

</style>